/**
 * @license
 *
 * Font Family: Erode
 * Designed by: Nikhil Ranganathan
 * URL: https://www.fontshare.com/fonts/erode
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * Erode Variable(Variable font)
 * Erode Variable Italic(Variable font)
 * Erode Light
 * Erode Light Italic
 * Erode Regular
 * Erode Italic
 * Erode Medium
 * Erode Medium Italic
 * Erode Semibold
 * Erode Semibold Italic
 * Erode Bold
 * Erode Bold Italic
 *
*/

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 300.0;
*
* available axes:

* 'wght' (range from 300.0 to 700.0)

*/

@font-face {
    font-family: 'Erode-Variable';
    src: url('../public/fonts/erode/Erode-Variable.woff2') format('woff2'),
        url('../public/fonts/erode/Erode-Variable.woff') format('woff'),
        url('../public/fonts/erode/Erode-Variable.ttf') format('truetype');
    font-weight: 300 700;
    font-display: swap;
    font-style: normal;
}

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 300.0;
*
* available axes:

* 'wght' (range from 300.0 to 700.0)

*/

@font-face {
    font-family: 'Erode-VariableItalic';
    src: url('../public/fonts/erode/Erode-VariableItalic.woff2') format('woff2'),
        url('../public/fonts/erode/Erode-VariableItalic.woff') format('woff'),
        url('../public/fonts/erode/Erode-VariableItalic.ttf') format('truetype');
    font-weight: 300 700;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'Erode-Light';
    src: url('../public/fonts/erode/Erode-Light.woff2') format('woff2'),
        url('../public/fonts/erode/Erode-Light.woff') format('woff'),
        url('../public/fonts/erode/Erode-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Erode-LightItalic';
    src: url('../public/fonts/erode/Erode-LightItalic.woff2') format('woff2'),
        url('../public/fonts/erode/Erode-LightItalic.woff') format('woff'),
        url('../public/fonts/erode/Erode-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'Erode-Regular';
    src: url('../public/fonts/erode/Erode-Regular.woff2') format('woff2'),
        url('../public/fonts/erode/Erode-Regular.woff') format('woff'),
        url('../public/fonts/erode/Erode-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Erode-Italic';
    src: url('../public/fonts/erode/Erode-Italic.woff2') format('woff2'),
        url('../public/fonts/erode/Erode-Italic.woff') format('woff'),
        url('../public/fonts/erode/Erode-Italic.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'Erode-Medium';
    src: url('../public/fonts/erode/Erode-Medium.woff2') format('woff2'),
        url('../public/fonts/erode/Erode-Medium.woff') format('woff'),
        url('../public/fonts/erode/Erode-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Erode-MediumItalic';
    src: url('../public/fonts/erode/Erode-MediumItalic.woff2') format('woff2'),
        url('../public/fonts/erode/Erode-MediumItalic.woff') format('woff'),
        url('../public/fonts/erode/Erode-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'Erode-Semibold';
    src: url('../public/fonts/erode/Erode-Semibold.woff2') format('woff2'),
        url('../public/fonts/erode/Erode-Semibold.woff') format('woff'),
        url('../public/fonts/erode/Erode-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Erode-SemiboldItalic';
    src: url('../public/fonts/erode/Erode-SemiboldItalic.woff2') format('woff2'),
        url('../public/fonts/erode/Erode-SemiboldItalic.woff') format('woff'),
        url('../public/fonts/erode/Erode-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'Erode-Bold';
    src: url('../public/fonts/erode/Erode-Bold.woff2') format('woff2'),
        url('../public/fonts/erode/Erode-Bold.woff') format('woff'),
        url('../public/fonts/erode/Erode-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Erode-BoldItalic';
    src: url('../public/fonts/erode/Erode-BoldItalic.woff2') format('woff2'),
        url('../public/fonts/erode/Erode-BoldItalic.woff') format('woff'),
        url('../public/fonts/erode/Erode-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: italic;
}
