/**
 * @license
 *
 * Font Family: Clash Grotesk
 * Designed by: Indian Type Foundry
 * URL: https://www.fontshare.com/fonts/clash-grotesk
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * Clash Grotesk Variable(Variable font)
 * Clash Grotesk Extralight
 * Clash Grotesk Light
 * Clash Grotesk Regular
 * Clash Grotesk Medium
 * Clash Grotesk Semibold
 * Clash Grotesk Bold
 *
*/

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

@font-face {
    font-family: 'ClashGrotesk-Variable';
    src: url('../public/fonts/clash-grotesk/ClashGrotesk-Variable.woff2') format('woff2'),
        url('../public/fonts/clash-grotesk/ClashGrotesk-Variable.woff') format('woff'),
        url('../public/fonts/clash-grotesk/ClashGrotesk-Variable.ttf') format('truetype');
    font-weight: 200 700;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'ClashGrotesk-Extralight';
    src: url('../public/fonts/clash-grotesk/ClashGrotesk-Extralight.woff2') format('woff2'),
        url('../public/fonts/clash-grotesk/ClashGrotesk-Extralight.woff') format('woff'),
        url('../public/fonts/clash-grotesk/ClashGrotesk-Extralight.ttf') format('truetype');
    font-weight: 200;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'ClashGrotesk-Light';
    src: url('../public/fonts/clash-grotesk/ClashGrotesk-Light.woff2') format('woff2'),
        url('../public/fonts/clash-grotesk/ClashGrotesk-Light.woff') format('woff'),
        url('../public/fonts/clash-grotesk/ClashGrotesk-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'ClashGrotesk-Regular';
    src: url('../public/fonts/clash-grotesk/ClashGrotesk-Regular.woff2') format('woff2'),
        url('../public/fonts/clash-grotesk/ClashGrotesk-Regular.woff') format('woff'),
        url('../public/fonts/clash-grotesk/ClashGrotesk-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'ClashGrotesk-Medium';
    src: url('../public/fonts/clash-grotesk/ClashGrotesk-Medium.woff2') format('woff2'),
        url('../public/fonts/clash-grotesk/ClashGrotesk-Medium.woff') format('woff'),
        url('../public/fonts/clash-grotesk/ClashGrotesk-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'ClashGrotesk-Semibold';
    src: url('../public/fonts/clash-grotesk/ClashGrotesk-Semibold.woff2') format('woff2'),
        url('../public/fonts/clash-grotesk/ClashGrotesk-Semibold.woff') format('woff'),
        url('../public/fonts/clash-grotesk/ClashGrotesk-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'ClashGrotesk-Bold';
    src: url('../public/fonts/clash-grotesk/ClashGrotesk-Bold.woff2') format('woff2'),
        url('../public/fonts/clash-grotesk/ClashGrotesk-Bold.woff') format('woff'),
        url('../public/fonts/clash-grotesk/ClashGrotesk-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}
