@font-face {
    font-family: 'ALT-Riviera-Bold';
    font-style: normal;
    font-weight: normal;
    src:
        local('ALT-Riviera-Bold'),
        url('../public/fonts/alt-riveria/ALTRiviera-Bold.woff2') format('woff2'),
        url('../public/fonts/alt-riveria/ALTRiviera-Bold.woff') format('woff'),
        url('../public/fonts/alt-riveria/ALTRiviera-Bold.ttf') format('ttf');
}
