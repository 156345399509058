@font-face {
    font-family: 'SpaceMono-Regular';
    src: url('../public/fonts/space-mono/SpaceMono-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'SpaceMono-Italic';
    src: url('../public/fonts/space-mono/SpaceMono-Italic.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'SpaceMono-Bold';
    src: url('../public/fonts/space-mono/SpaceMono-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'SpaceMono-BoldItalic';
    src: url('../public/fonts/space-mono/SpaceMono-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: italic;
}
